// .camapigns-texbox-input {
//     width:100%;
//     height:40px!important;
//     font-size:14px;
//    }
.campaignSettings-footer {
    margin-top: 20px;
    padding-left: 0px;
    // border-bottom:none!important
}
// .campaignSettings-footer Button {
//     margin-left: 10px;
//     width: 20%;
// }
// .right-align Button {
//     float: right;
// }

// .cstPanel-try{
//     border-bottom: none!important;
//     height: 50px;
// }

// .custom-row{
//     // height: 80px;
//     // // min-height: 40px;
//       border-bottom:1px solid rgba(0, 0, 0, 0.125);
// }

.gridbuttonrow{
    padding-bottom: 5px; 
 }

 .campaignResultsgridButtons{
     margin-right:15px;
    margin-left:auto;
}
.vertical-line {
    width: 1px; /* Adjust the width of the vertical line */
    height: 40px; /* Adjust the height of the vertical line to match button height */
    background-color: grey; /* Color of the vertical line */
    margin: 0 10px; /* Adjust the margin to control the spacing between buttons and the line */
  }