/* Bootstrap overrides */

@import "./_variables";

/* Import bootstrap */

@import "~bootstrap/scss/bootstrap";
@import '~bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!

/* Other CSS Imports */
/* poppins-regular - latin */
@font-face {
	font-display: swap;
	/* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: url('../shared/fonts/poppins/poppins-v21-latin-regular.woff2') format('woff2');
	/* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-500 - latin */
@font-face {
	font-display: swap;
	/* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	src: url('../shared/fonts/poppins/poppins-v21-latin-500.woff2') format('woff2');
	/* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-700 - latin */
@font-face {
	font-display: swap;
	/* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	src: url('../shared/fonts/poppins/poppins-v21-latin-700.woff2') format('woff2');
	/* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-condensed-regular - latin */
@font-face {
	font-display: swap;
	/* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 400;
	src: url('../shared/fonts/roboto-condensed/roboto-condensed-v27-latin-regular.woff2') format('woff2');
	/* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-condensed-700 - latin */
@font-face {
	font-display: swap;
	/* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 700;
	src: url('../shared/fonts/roboto-condensed/roboto-condensed-v27-latin-700.woff2') format('woff2');
	/* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-regular - latin */
@font-face {
	font-display: swap;
	/* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url('../shared/fonts/roboto/roboto-v32-latin-regular.woff2') format('woff2');
	/* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-700 - latin */
@font-face {
	font-display: swap;
	/* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: url('../shared/fonts/roboto/roboto-v32-latin-700.woff2') format('woff2');
	/* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

// Floating label overrides
$input-height: 42px;
$input-padding-y: 0.7rem;
$input-padding-x: 0.5rem;
$form-floating-height: $input-height;
$form-floating-padding-y: $input-padding-y;
$form-floating-padding-x: $input-padding-x;
$form-floating-input-padding-t: 0.8rem;
$form-floating-input-padding-b: 0rem;

.form-floating {
  > .form-control {
    height: $form-floating-height;
	min-height: $form-floating-height;
    padding: $form-floating-input-padding-t $form-floating-padding-x $form-floating-input-padding-b;
    
    &::placeholder {
      color: transparent;
    }
    
    &:not(:placeholder-shown) {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;

	  ~ label::after {
        background-color: transparent;
        height: 1em;
      }
    }
    
    &:focus {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;

	  ~ label::after {
        background-color: transparent;
        height: 1em;
      }
    }
  }

  > label {
    padding: $form-floating-padding-y $form-floating-padding-x;
	font-size: 13px;
  }

}

.poppins-regular {
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-style: normal;
}

.poppins-medium {
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-style: normal;
}

.poppins-bold {
	font-family: "Poppins", sans-serif;
	font-weight: 700;
	font-style: normal;
}

.roboto-condensed-regular {
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 400;
	font-style: normal;
}

.roboto-condensed-bold {
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 700;
	font-style: normal;
}

.roboto-regular {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	font-style: normal;
}

.roboto-bold {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	font-style: normal;
}

body {
	font-family: "Roboto", Arial, sans-serif;
}

:root {
    --bs-nav-link-font-size: 12px;
    --bs-light-rgb: 248, 249, 250;
    --bs-border-color-translucent: rgb(222, 226, 230);
	--marginLeftCustomerRow: 100px;
  }

.card.clear-card-body {
	padding: 0;
	border: none !important;
	box-shadow: none;
	background-color: transparent;
	> .card-header {
		padding-left: 0;
		padding-right: 0;
		.panel-title {
			text-transform: uppercase;
		}
	}
	> .card-body {
		padding-top: 0;
		padding-right: 0;
	}
	.card:first-child {
		margin-left: 1rem;
		.panel-title {
			letter-spacing: 0.02em;
			text-transform: uppercase;
			font-size: small;
		}
	}
}

.slider-base {
	z-index: 2000 !important; // fix for slider z-index issue with Contact sticky sidebar in Customer module
}

.remove-right-radius {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.remove-left-radius {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.form-control:disabled, .form-select:disabled,
.form-floating > :disabled ~ label::after, .form-floating > .form-control:disabled ~ label::after {
	background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.mh-auto {
	max-height: unset !important;
}

/* React Bootstrap Typeahead BS5 styles */
.rbt-close {
	font-size: 1rem;
  }
  .rbt-close-sm {
	font-size: 0.75rem;
  }
  .rbt-close-content {
	display: none;
  }
  
  .rbt-aux {
	width: 2.5rem;
  }
  .rbt-aux-lg {
	width: 3rem;
  }
  .rbt-aux .rbt-close {
	margin-top: 0;
  }
  
  .rbt .form-floating {
	flex: 1;
  }
  
  .form-floating > .rbt-input-multi:not(:placeholder-shown) ~ label {
	opacity: inherit;
	transform: inherit;
  }
  .form-floating > .rbt-input-multi .rbt-input-main::placeholder {
	color: transparent;
  }
  .form-floating > .rbt-input-multi.focus ~ label {
	opacity: 0.65;
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }
/* All component level SCSS files are imported @ component level */