.sld-task-action-row {
    margin-top: 1em;
}

.sld-task-col {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.sld-task-section-header {
    font-weight: bold;
    font-size: 1.1em;
}

.sld-task-input-error {
    margin-top: 0em;
}

.sld-task-descr-input {
    height: 6rem !important;
    margin-bottom: 2.2em;
}

.sld-task-col-individual-lable {
    padding-left: 0px;
}

.sld-task-action-select-div {
    margin-top: -0.8rem !important;
    margin-bottom: 0.3em !important;
  }

