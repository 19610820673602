.sliding-component {
    position: absolute; 
    top: 0;
    left: 100vw; 
    width: 50%; 
    height: 100%; 
    transition: transform 0.3s ease-in-out; 
  }

  .sliding-component.show {
    transform: translateX(-115.5%); 
  }

  .slider-detached {
    display: none;
  }

  .slider-base {
    min-width: 40rem;
    max-width: 40rem;
    background-color: rgba(245, 245, 245, 0.98);
    z-index: 1;
    height: auto;
    margin-top: 0.1em;
    padding: 0.5em;
    border: 1px solid lightblue;
    border-radius: 0.4em;
  }

  .slider-pinned {
    min-width: 100%;
    max-width: 100%;
  }

  .slider-prevent-scroll {
    overflow-x: hidden;
  }