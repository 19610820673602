.indexingPanel{
    max-width: 50%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    padding: 10px;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.gridHeader{
    text-align: center; /* Center the column names */
}
.gridCell {
    color: #207ab7 !important;
    font-weight: 600;
    text-align: center;
}
.tbl-main.gridCell.table {
    tr.data-row-1 {
    background-color: white !important;
}
    th {
        height: fit-content !important;
    }
}

.cell-td.Active {
    border: 1px solid transparent;
    border-radius: 5px;
    color: #50CD89 !important;
    background-color: #E8FFF3;
    padding: 10px !important;
    text-align: center;
}

.cell-td.Inactive {
    border: 1px solid transparent;
    color: #FF6F6F !important;
    background-color: #FFEBEB;
    padding: 10px !important;
    text-align: center;
}
.refNumberRadio{
    display: flex;
    justify-content: space-between;
    padding-right: 20px !important;
    padding-left: 20px !important;
}
.indexingLabel {
    color: #646464;
    font-weight: 700;
    padding-left: 15px;
}
.policiesList {
    display: flex;
    height: 100%;
    font-weight: 400;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
    cursor: pointer;
}
.policiesList.clicked {
    background-color: #EFF6FF;
    border-radius: 5px;
    color: #3B82F6;;
}
.termsList {
    display: flex;
    margin-left: 1.4em;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 100%;
    font-weight: 400;
    align-items: center;
    cursor: pointer;
}
.termsList.selectedTerm {
    background-color: #EFF6FF;
    border-radius: 5px;
    color: #3B82F6;;
}
.policyIcons {
    color: #000 !important;
    padding-right: 5px;
    padding-left: 5px;
}