.cd-main-container {
    // margin-bottom: 0.5em;
    // padding: 0.5em;
    // border-radius: 0.5em;
    //border-bottom: 1px solid darkgrey;
}

.cst-dp-div-actions {
    display: flex;
    justify-content: end;
}
input[type=number].no-spinner::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
-webkit-appearance: none; 
 margin: 0; 
}