// table {
//   border-spacing: 0;
// }

.table-container {
  overflow: auto;
  max-height: 400px;
  max-width: 100%;
}

// table tr:last-child td {
//   border-bottom: 0;
// }

// table th,
// table td {
//   margin: 0;
//   padding: 0.5rem;
//   line-height: 1rem;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   //  overflow: visible;
//   //hidden;--Chitranshi;
//   white-space: nowrap;
//   border-collapse: collapse;
//   border-style: hidden;
//   font-size: 14px;
//   //  width: 100% !important; //Chitranshi
// }

// table th {
//   // height: var(--table-header-height);
// 	font-family: 'Roboto Condensed', sans-serif;
// }

// table thead {
//   color: #3F4254;
//   top: 0;
//   position: sticky;
//   z-index: 1;
//   border-collapse: collapse;
// }

// table tr {
//   border-bottom: 2px solid white;
//   overflow: visible; // important
// }

// table th:last-child,
// table td:last-child {
//   border-right: 0;
// }

// table tr:nth-child(even) {
//   background-color: #fafafa;
// }

// table th::before {
//   position: absolute;
//   right: 15px;
//   top: 16px;
//   content: "";
//   width: 0;
//   height: 0;
//   border-left: 5px solid transparent;
//   border-right: 5px solid transparent;
// }


// table th.sort-asc::before {
//   border-bottom: 5px solid #333;
// }

// table th.sort-desc::before {
//   border-top: 5px solid #333;
// }


// input {
//   padding: 10px;
//   margin-bottom: 20px;
//   font-size: 18px;
//   border-radius: 5px;
//   border: 1px solid #ddd;
//   box-shadow: none;
// }

.resizer {
  display: inline-block;
  background: #e3e3ea;
  width: 1px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  // transform: translateX(50%);
  z-index: 1;
  /* prevent scroll on touch devices */
  touch-action: none;
}


.isResizing {
  background: red;
}

.highlighted-row {
  background-color: lightblue !important;
  /* Use the color you want for highlighting */
}

.selected-row input[type="checkbox"]:checked~.highlighted-row {
  background-color: transparent;
  /* Remove highlighting styles for selected rows */
}

// .gridrow.row{
// margin-right: 0px !important; //--Added by Chitranshi to manage table resizing as per screen resolution
// }

// .cstGrid{
//   padding-left: 15px !important; //--Added by Chitranshi to manage table resizing as per screen resolution
// }
table.table {
  th {
    &:first-child {
      padding-left: 12px;
    }

    // &:last-child {
    //   border-top-right-radius: 12px;
    //   border-bottom-right-radius: 12px;
    // }
  }
  td {
    > div {
      display: flex;
      height: 100%;
      align-items: center;
    }
  }
}

.gridNavigateButton {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  border-radius: 6px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

// .gridNavigateButton:hover {
//   background-color: #f5f5f5;
// }