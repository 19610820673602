.documateHeader.form-label {
    padding-left: 0.5rem;
}
.refiPanel{
    width: 50%;
    max-width: 50%;
    height: 100%;
    // border: 1px solid #ccc;
    // border-radius: 4px;
    // background-color: white;
    padding: 0 0 10px 10px;
    margin-right: 1rem;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .card-header {
        padding-bottom: 0 !important;
    }

    .card {
        margin-bottom: 0.5rem;
    }
}
.button-container{
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}
.approve-btn {
    flex:1;
    background-color: #15803D;
    color: white;
    border: none;
    padding: 15px;
    margin: 0 5px;
    cursor: pointer;
    text-align: center;
}
.approve-btn:hover {
    background-color: #0D5F2D;
}
.reject-btn {
    flex:1;
    background-color: #B91C1C;
    color: white;
    border: none;
    padding: 15px;
    margin-right: 5px;
    cursor: pointer;
    text-align: center;
}
.reject-btn:hover {
    background-color: #7F1D1D;
}
.close-btn-div {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    cursor: pointer;
}
.close-btn-div2 {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    padding-top: 5px;
}
.dropdown-btn-grp{
    display: flex;
    flex-direction: column !important;
    cursor: pointer;
}