.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  /* Behind the navbar */
  padding: 0 0 0;
  /* Height of navbar */
  /* border-right: 1px solid rgba(0, 0, 0, .1); */
  background-color: #104569 !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 20px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

.burgerMenu {
  background-color: rgba(0, 0, 0, 0.2); /* Darken the background */
  /* border: 1px dashed #727272; 1px wide dotted border */
  border-radius: 9px !important; /* 9px radius */
  padding: 7px 0px !important;
}

.sideBarListItemsCollapsed {
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  padding-left: 10px;
  padding-right: 1rem;

}

.sideBarListItemsExpanded {
  cursor: pointer;
  font-size: small;
  text-align: left;
  padding-left: 10px;
  padding-right: 2rem;
}

.user-initials-circle {
  width: 36px;
  height: 36px;
  background-color: lightgray;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  color: black;
  cursor: pointer;
}

.logInUserName{
  color:white;
  padding-left:20px;
  font-size: 12px;
  white-space: normal;
  overflow-wrap: break-word;
  width: auto;
}

.logInUserNameInitials{
  font-size: small;
  color:rgb(131, 0, 0);
  left:80%;
}

/* .sidebar-dropdown-item {
  padding: 5px 5px;
  font-size: 14px;
  line-height: 1;
  color: #212529;
  background-color: #fff;
  border: none;
  cursor: pointer;
  text-align: left;
  width: 100%;
  display: block;
  position: relative;
} */

.sidebar-dropdown-item:hover {
  background-color: #f8f9fa;
}

.fa-layers.fa-fw:hover {
  cursor: pointer;
}

.logInUserName:hover {
  cursor: pointer;
}

.sidebar-dropdown-menu {
  padding: 0.5rem 0;
  background: #ffffff;
  /* border: 0 none; */
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

/* .userDetails {
  display: flex;
  align-items: center; 
} */

.hidden {
  display: none;
}

#dropdown-basic.dropdown-toggle::after {
  display: none;
}

.sidebarExpandedLabel {
  display: flex;
  align-items: flex-start;
}

/* @supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
} */

