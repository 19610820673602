
.activity-summary {
    &--main-div {
       margin: 0.5em;
       padding-right: 0.5em;
    }

    &--icon-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding-left: 0.4em;
    }

    &--item-icon {
        font-size: 1em;
        color: #3880F6;
    }

    &--detail {
        display: flex;   
        flex-direction: column;
    }

    &--header {
        display: flex;
        justify-content: space-between;
    }

    &--customer {
        font-size: 0.9em;
        font-weight: bold;
    }

    &--secondary-content {
        font-size: 0.8em;
        color: gray;
    }
}