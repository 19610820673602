.task-dashboard-card {
    

    &--header {
        padding: 0.5em;
        font-weight: bold;
        display: flex;
        column-gap: 0.5em;
    }

    &--content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 22em;
    }

    &--pagination {
        display: flex;
        justify-content: center;
    }
}