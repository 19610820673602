.bulkActionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    margin-bottom: 5px;
    background-color: #EFF6FF;
    color: grey;
    border-radius: 4px;
}

.parent-container {
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   height: calc(100vh - 50px); /* or adjust accordingly */
}

// .docs-container {
//    max-width: 50%; /* Fixed to 50% width */
//    height: 800px;
//    border: 1px solid #ccc;
//    border-radius: 4px;
//    background-color: #fff;
//    padding: 10px;
//    position: relative;
//    flex: 1;
//    display: flex;
//    justify-content: space-between;
//    flex-direction: row; /* Ensures proper stacking if needed */
//    // overflow: auto;
//  }
 .doc-view-container {
   width: 50%;
   height: calc(100vh - 50px); /* or adjust accordingly */
   border: 1px solid rgb(222, 226, 230);
   border-radius: 6px;
   background-color: #fff;
   padding: 10px;
   margin-right: 1rem;
   flex: 1;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   position: relative;
   overflow: hidden;
}

//  .left-panel,
//  .right-panel {
//    width: 50%;
//    padding: 10px;
//    border: 1px solid #ddd;
//    border-radius: 4px;
//    overflow: auto;
//    margin: 5px;
//  }
 .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    color: grey;
    position: relative;
    padding-left: 5px;
 }
 .file-list {
   margin-top: 10px;
 }
 .file-item {
    display: flex;
    align-items: center;
    padding: 5px;
    font-size: 14px;
    margin-bottom: 8px;
    margin-top: 10px;
 }
 .file-item:hover {
    background-color: #EFF6FF;
 }
 .file-item.dragging {
   background-color: #EFF6FF;
}
// .file-item.selected {
//    background-color: #EFF6FF;
// }
.file-item.clicked {
   background-color: #EFF6FF;
}
 .left-panel input[type="checkbox"] {
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 8px;
 }
 .folder {
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
 }
 .subfolder {
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 10px;
      margin-left: 10px;
 }
 .folder-icon{
      margin-right: 5px;
      font-size: medium;
 }
 .subfolder-icon {
   margin-right: 5px;
 }
 .dest-file-item {
   display: table;
   padding: 5px;
   font-size: 14px;
   color: #3B82F6;
   // background-color: #e6f3ff;
   margin-bottom: 8px;
   margin-top: 5px;
   margin-left: 20px;
 }
 .dest-file-item.clicked {
   background-color: #EFF6FF;
 }
 .dest-file-item:hover {
   background-color: #EFF6FF;
 }
 .count {
   background-color: orange;
   font-size: 12px;
   color: black;
   height: 25px;
   width: 25px;
   padding: 5px;
   display: inline-block;
   margin-left: 5px;
   margin-right: 5px;
   border-radius: 5px;
   position: absolute;
   right: 0;
   margin-bottom: 10px;
   font-weight: normal;
   text-align: center;
}
.trash-icon {
   color: black;
   font-size: 14px;
   cursor: pointer;
   display: inline-block;
   align-items: center;
   position: absolute;
   margin-left: 10px;
   padding: 2px;
   // right: 30px;
}
.trash-icon:hover {
   border: 2px solid red;
   border-radius: 5px;
}