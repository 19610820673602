
.customer-slider-main-div {
    width: 100%;
    background-color: blue($color: #000000);
    display: flex;
    flex-direction: column;
}

.temp-component-place-holder-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    font-weight: bold;
}

.pin-icon-pinned {
    transform: rotate(270deg);
}

.slider-menu-header-nav-bar {
    padding: 0.2em;
}

.sld-menu-icons {
}
.sld-menu-icons:hover {
}

.sld-col-section-header {
    padding-left: 0.5em;
    margin-bottom: 1em;
}

.sld-inlineLable {
    vertical-align: sub;
    margin-top: 0.1em;
}

.slider-tab-content-main-div {
    width: 100%;
    padding: 0.5em;
}

.sld-action-btn-base {
    min-width: 7.4rem;
}
