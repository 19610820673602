.labelStyle{
    font-size:small;
  }
  .rightBorder{
    border-right:gray solid;
  }

  .inputStyle{
    width:100%;
    height:30px;
    font-size:small;
   }

   .paddingTop5{
     padding-top:5px;
   }

   .paddingTop1{
    padding-top:1px;
  }
  .padding1{
    padding:1px;
  }
  .nopadding{
    padding:0px;
  }
  .paddingleft25{
    padding-left: 25px;
  }
.noPaddingLeft{
  padding-left: 0px !important;
}
  .bottomBorder{
    border-bottom:gray solid;
  }

  .bottompadding{
    padding-bottom: 5px;
  }

  .paddingtop25{
    padding-top:25px;
  }
  .bordernone{
    border:none;
  }
  .paddingleft20{
    padding-left: 20px;
  }

  .gridButtons{
      margin-right:15px;
  }
  .gridMailMerge{
    margin-right:10px;
    margin-left:auto;
  }

  .gridbuttonrow{
     padding-bottom: 5px; 
     justify-content: space-between;
  }

  .gridrow{
    padding-top:20px;
    border-top: solid grey; 
    margin-right:0px !important
  }

  .topButton{
    position: fixed;
    bottom: 20px;
    right: 27px;
    border: 'none';
    background-color: 'cyan-blue',
  }

  .searchButton{
    position:fixed;
    bottom: 55px;
    right: 32px;
    border: 'none';
    background-color: 'cyan-blue',
  }

  .searchButtonFixed{
    position: absolute;
    bottom: 46px;
    right: 20px;
    border: 'none'!important;
    background-color: 'cyan-blue' !important
  }
.paddingleft0{
  padding-left: 0;
}
.bg-gray{
background-color: #f8f8f8;
}
.highlightTab{
  border-bottom:2px solid #ff6900;
  color: #004c97 !important;
  font-weight: bold;
}
.normalTab{
  border-bottom:none;
  color: #adadad !important;
  font-weight: bold;
}
.labelHeading{
  font-size: medium;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  /* position: absolute; */
  top: 13px;
  padding: 0;
  background-color: transparent;
  font-size: 14px !important;
  margin-left: -5px;
}
.inlineLable{
  /* text-align: left;
    padding-left: 10px !important;
    color: black;
    font-size: 12px !important;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.1px; */
}
.noRadiusBorder{
  border-radius: 0em!important ;
}
/* .react-datepicker-wrapper input[type="text"] {
  border-top-left-radius:0em!important ;
  border-bottom-left-radius:0em!important ;
} */
.hidden{
  visibility: hidden;
}
.labelPadding{
  padding-top: 16px;
}
.cstDatepickerHeight input[type="text"],.cstDatepickerHeight span.input-group-text{
  height:30px;
}
.datepickerMarginInsurance{
margin-top:-40px;
}
.datepickerMarginTo{
  margin-top:-20px;
  }
  .rowMaxHeight{
  max-height: 35px !important;
  }
  /* .bg-gray .row{
    max-height: 35px !important;
  } */

  .main-bg-white{
    background-color: white;
  }
  
  .datepickerWidth{
    width: 28% !important;
  }
  .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  .btn-secondary :hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  .searchBtnMargin{
    margin-top: -2px;
  }
  /* .cstCheckbox label
  {
    margin-top: -17px;
  } */
  .cstCheckbox input
  {
    margin-bottom: 0px !important;
  }
  .btnLabelStyle{
    font-size: small;
    min-width: 89px !important;
  }
  .gridButtonColumn{
    max-width:11%!important;
    flex: 1 0 11% !important;
  }
  .gridButtonSupportColumn{
    max-width:45%!important;
    flex: 0 0 45% !important;
  }
  /* .cstTable table tr th{
    font-size: medium;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  /* position: absolute; 
  top: 2px;
  text-align: center;
  padding-top: 7px;
  background-color: transparent; 
  font-size: 12px !important;
  background-color:#3f3b3b2b;
  background-image: linear-gradient(to bottom, #ffffff,#fcfcfc, #ededed);
  vertical-align: middle !important;
  background-color: #fcfcfc;
  height: 28px;
   border: 1px solid #b2b0b0; 
  }

  .cstTable table tr td{
    font-size: medium;
  font-weight: normal;
  color: #333;
   position: absolute; 
  top: 13px;
  text-align: center;
  padding-top: 7px;
  background-color: transparent;
  font-size: 12px !important;
  border: 1px solid #b2b0b0; 
  height: 28px;
  background-color:#f8f8f8; 
  }
  .cstTable table thead{
    background-color:#f8f8f8;
  }

  .cstTable table tr:nth-of-type(even) {
    background-color: #eef3fc;
}

.cstTable table tr:hover {
  background-color: #f5f5f5;
} */
.pagingSelect{
  max-width: 63px;
    text-align: center;
}

.labelPanelHeading{
  font-size: medium;
  font-weight: bold;
  color: #333;
  /* text-transform: uppercase; */
  /* position: absolute; */
  top: 13px;
  padding: 0;
  background-color: transparent;
  font-size: 16px;
  margin-left: -5px;
}
.paddingLeft40{
  padding-left: 40px;
}
.paddingLeft17{
  padding-left: 17px;
}

.sidebarToggle{
  margin-left: var(--marginLeftCustomerRow);
}
.cstLowBottomSpacing .row {
  margin-bottom: -10px !important;
}
.paddingBottom20{
  padding-bottom: 20px;
}
.marginBottom35{
  margin-bottom: -35px;
}

/* #ToPremiumTextInput{
  width: 95% !important;
} */

.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.icon {
  padding: 10px;
  min-width: 40px;
}

/* #QuickSearchTextInput.input-field {
  width: 100%;
  padding: 10px;
  text-align: center;
} */
#QuickSearchTextInput{
  width: 194% !important;
  height: 38px!important;
  font-size: small;
  /* margin-top: 20px!important; */
}
.cstQuickSearch .input-group{
  margin-top: 10px!important;
}
.inputGroupSearch:hover{
  cursor: pointer !important;
  background-color: silver;
  border-color: #bababa;

}

.inputGroupSearchAppend:focus {
  color: #212529 !important;
  background-color: silver !important;
  border-color: #bababa !important;
  box-shadow: 0 0 0 0.2rem rgba(184, 185, 186, 0.5) !important;
}
.hidden{
  display:none;
}

.cstErrorTopMargin .mt-n1{
  margin-top: -1.5rem !important;
}

.formated_contact_error .upmargin20{
  margin-left: 15%;
  margin-right: -18%;
}