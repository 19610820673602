.customToggle{
    cursor: pointer;
    font-weight: bold;
}

.activeStatus {
    color: #50CD89;
    background-color: #E8FFF3;
    padding: 4px;
    text-align: center;
}

.inactiveStatus {
    color: #FF6F6F;
    background-color: #FFEBEB;
    padding: 4px;
    text-align: center;
}