
.texbox-input-container {
    //padding-left: 2.25rem !important;
}

.dpd-crd-body {
    text-align: left; 
    padding: 1em 0em;
}

.dv-cst-actions {
    display: flex;
    justify-content: end;
    margin: 0em;
}
// .customerPanel .row>[class^="col-lg"]{
//     height: 52px !important;
//     text-align: -webkit-left;
//     border-bottom: 0px !important;
//  }

 .customerPanel .feedback-row{
    border-bottom: 0px !important;
 }
//  .cstCustAlign .card-body{
//     padding-left: 25px;
//  }
//  .customerPanel .card-body{
//     padding-bottom: 0px;
//  }

// .cstCustAlign .cd-main-container:not(:first-child){
//     border-top: 1px solid darkgrey;
// }