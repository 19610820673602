// .container {
//     display: flex;
//      height: 100vh;
//     background-color: #fff;
// }

.role-privilege-main{
     overflow: hidden !important;
}

// .new-role-border {
//     padding-bottom: 10px;
//     border-bottom: 1px solid rgba(0, 0, 0, 0.125);
//     padding-top: 10px;
// }

// .roles-sidebar {
//      height:100%;
//      width:100%;
//      direction:rtl;
//      scrollbar-width:thin;
//      max-height: calc(100vh - 172px);
// }

.screen-privilege {
     // margin-left: -50px;
     //  background-color: lightgray;  
     //   height: 100%;
     //  min-height: calc(100vh - 172px);
     //  max-height:100%;
     //  overflow-y: auto;
}

.role-details {
//     margin-left: -30px;
    margin-bottom: 0px;
}
