

.sld-act-section-header {
    font-weight: bold;
    font-size: 1.1em;
}

.inlineLable-form {
    vertical-align: sub;
}

.sld-act-descr-input {
    height: 10rem !important;
    margin-bottom: 2.2em;
}

.sld-act-col-individual-lable {
    padding-left: 0px;
}

.sld-act-col-individual-input {
    padding-left: 0em;
}



.sld-act-time-picker {
    width: 8rem;
}


.sld-act-input-error {
    margin-top: 0em;
}
