.cst-task-main-div {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    padding: 0.5em 0em 0.5em 0em;
}

.cell-view-badge-div {
    display: flex;
    justify-content: center;
}

.cell-view-badge {
    font-size: 1em;
}

.cell-view-multiline-component {
    display: flex;
    flex-direction: column;
}

.cell-view-multiline-component--detail {
    font-size: 0.9em;
    color: #606060;
    font-weight: 400;
}

.grid-expander-icon {
    color: #0353a3;
    vertical-align: text-top;
    margin-right: 0.3em;
}


// Low task priority
.cell-view-badge--priority-10 {
    background-color: blue;
    color: white;
}

// Norma task priority
.cell-view-badge--priority-5 {
    background-color: darkorange;
    color: white;
}

// Critical task priority
.cell-view-badge--priority-1 {
    background-color: red;
    color: white;
}

// task status - not completed
.cell-view-badge--status-1 {
    background-color: darkorange;
    color: white;
}

// task status - completed
.cell-view-badge--status-2 {
    background-color: green;
    color: white;
}

.tsk-grd-descr-cell {
    display: inline-block;
}

.tsk-grd-descr-cell--detail {
    display: inline-block;
}

.tsk-grd-descr-cell--detail-expanded {
    display: inline-block;
    overflow-y: auto;
    white-space: pre-wrap;
    max-height: 5em;
}

.tsk-grd-descr-cell--expander {
    display: inline-block;
    vertical-align: top;
}

.tsk-cell-view-created-by-icon {
    margin-left: 1em;
}

.task-due-date-past {
    color: red
}

.tsk-grd-div {
    height: 75vh;
}

.tsk-grd-th {
    height: 60px !important;
}

.tsk-grd-first-filter-lable {
    margin-left: 0.3rem;
}

.tsk-grd-filter-last-col {
    display: flex;
    justify-content: end;
}

.grd-table-container {
    height: -webkit-fill-available !important;
    max-height: -webkit-fill-available !important;
}

.grd-table {
    height: 92% !important;
}

.description-cell-edit {
    height: 6rem !important;
}

.task-history-grd-div {
    min-height: 18vh !important;
}

.task-history-table-container {
    height: 18vh !important;
    max-height: -webkit-fill-available !important;
}

.task-history-modal {
     max-width:73vw !important;
    margin-top: 20vh;
}