.nav-link-custom {
    color: white !important; /* Default color */
    text-decoration: none;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin: 0 1em;
  }
  
  .nav-link-custom:hover {
    color: white; /* Hover color */
  }
  
  .nav-link-custom.active {
    color: #fff; /* Color for active link */
    font-weight: bold; /* Optional background color for active link */
    border-bottom: 0.15em solid darkorange;
  }

  .secondary-icon-menu-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
  }

  .primary-icon-menu-container {
    width: max-content;
  }