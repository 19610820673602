html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.navbar {
  
  top: 0;
  padding-left:200;
}

.navbar.navbar-dark {
  position: sticky;
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}
.align-right { 
  margin-left: auto!important;
}
.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.icn-website-launcher {
  transform: rotate(-45deg);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.breadcrumb {
  padding: 0;
  background-color: white;
}

.button-toolbar > button {
	margin-left: .5rem;
}

.react-datepicker-wrapper {
  flex-grow: 1;
}

.sidebar .list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #104569 !important;;
  border: 1px solid rgba(0, 0, 0, 0.125);
  color: #fff;
}

.sidebar .list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #207ab7 !important;
  border-left: 3px solid #FFF !important;
  padding-left: calc(0.25rem - 3px) !important;
}

main {
  margin-left: var(--marginLeftCustomerRow)
}

/* .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 80px !important;
  margin-right: auto;
  margin-left: auto;
} */

/* .bg-dark {
  background-color: white !important;
  color: black;
} */
.main-bg-light{
  background-color: rgb(238, 238, 238) !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: black;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: rgb(26 11 11);
}

.navbar-nav .cstHeaderMenu.nav-link.active{
  color: #004c97!important;
  /* text-decoration: underline !important; */
  /* background-color: transparent !important; */
  /* font-weight:bold; */
  /* text-shadow: 0 0 .65px #333, 0 0 .65px #333; */
 
}

.navbar-nav .cstHeaderMenu.nav-link:hover{
  color: #004c97 !important;;
}