.dashboard-main-container {
    height: 99.9vh;
    width: 95.5vw;
    margin-left: -0.8em;
    background-color: #edf1f7;
    padding: 1em;;
}

.main-dashboard-card {
    min-height: 25em;
    width: 36em;
    background-color: white;
    border-radius: 0.5em;
}

.default-badge {
    font-size: 1em;
    background-color: #3880F6;
    color: whitesmoke;
}

.default-badge-div {
    display: flex;
    justify-content: center;
}

.dashboard-row-item {
    display: flex;
    column-gap: 2em;
}