.tbl-main {
    width: 100%;
}

.tblHeader {
    background-color: white;
    color: black;
    border: none;
}

.data-row-1 {
    background-color: #f2f2f2; 
}

.data-row-1:hover {
    background-color:  rgb(214, 214, 214); 
}
  
.data-row-2 {
    background-color: transparent !important;
}

.data-row-2:hover {
    background-color: rgb(214, 214, 214) !important; 
}

.cell-td {
    width: 100%;
    vertical-align: middle !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cell-td-div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    table-layout: fixed;
}

.cl-expander-icon {
    color: #0353a3;
}

.disabled-icon { 
    color: gray; 
    pointer-events: none; 
    opacity: 0.5;
}