.checkbox-cell {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
}

.grid-container {
  display: flex;
  overflow: visible;
  margin-bottom: 2rem;

  .table-container {
    table {
      margin-bottom: 0;
      tr:nth-child(even) {
        background-color: lightgray !important
      }
      thead {
        text-align: center;
      }
      // td {
      //   height: 50px; /* Adjust the height as needed */
      //   margin-top: -5px;
      // }
    }
  }
}

// .grid-icon {
//   padding-left: 10px;
// }

.select-container {
  position: relative;
}

.delete-container {
  display: flex;
  align-items: center;
  justify-content: center; 
  cursor: pointer;
  height: 100%;
}

.add-container {
  cursor: pointer;
}