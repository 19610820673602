.panel-container {
    margin-top: 1em;
    margin-bottom: 1em;
}

.item-panel {
    padding: 0px;
}

.policy-num-div {
  display: flex;
  column-gap: 0.1em;
}

.policy-summary-icon {
  font-size: 1.5em;
    color: #3880F6;
}

.container-summary-main {
  display: flex; 
  overflow: hidden;
  position: relative;
    .background-lob-icon {
      position: absolute;
      left: 75%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.05;
      z-index: 0;
      pointer-events: none;
      height: 110%;
      display: flex;
      align-items: center;
  
      svg {
        height: 100%;
        width: auto;
      }
    }
  }
  
  .fixed-width {
    width: 4px;
    border-radius: 6px 0 0 6px;
  }
  
  .fill-width {
    flex: 1; 
    padding: 0.8em;
  }

  .btn-execpt-first {
    margin-left: 1em;
  }

  .policy-summary-action {
    align-content: center;
  }

  .subsequent-item-row {
    margin-top: 0.8em;
  }

  .lob-caption {
    font-size: 1.2em !important;
    color: black !important;
    font-weight: 600 !important;
  }

  .highlighted-value {
    font-weight: 700 !important;
  }

  .summary-dv-column {
    width: 100%;
  }

  .summary-dv-row {
    display: grid;
    grid-template-columns: 1.6fr 1.2fr 1.1fr 1.1fr 1fr;
    column-gap : 0.5em;
    width: 100%;
  }