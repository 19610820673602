
.task-summary {
    &--main-div {
       margin: 0.5em;
    }

    &--icon-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding-left: 0.4em;
    }

    &--item-icon {
        font-size: 1em;
        color: #3880F6;
    }

    &--detail {
     display: flex;   
     flex-direction: column;
    }

    &--customer {
        font-size: 0.9em;
        font-weight: bold;
    }

    &--tasktype {
        font-size: 0.8em;
        color: gray;
    }

    &--due-date-detail{
        display: flex;
        flex-direction: column;
    }

    &--due-date {
        font-size: 0.8em;
        color: gray;
        text-align: end;
    }

    &--due-days {
        font-size: 0.9em;
        font-weight: bold;
        text-align: end;

        &--past {
            color: red;
        }
    }
    
    &--priority-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    &--task-screen-arrow-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding-left: 0.5em;
    }

    &--task-badge--priority-1 {
        background-color: red;
        color: white;
        font-size: 0.9em;
    }

    &--task-badge--priority-5 {
        background-color: darkorange;
        color: white;
        font-size: 0.9em;
    }

    &--task-badge--priority-10 {
        background-color: blue;
        color: white;
        font-size: 0.9em;
    }
}