.entity-history-grd-div {
    min-height: 60vh;
}

.entity-history-grid-filter-input {
    max-height:30px !important;
}

.grd-entity-history-table-container {
    height: 60vh !important;
    max-height: -webkit-fill-available !important;
}

.grd-entity-history-table {
    height: 92% !important;
}