// .cmph-main-container {
//     padding-top: 0.5em;
// }

.cmph-highlighted-lbl {
    font-weight: bold;
}

.cmph-link {
    margin-right: 1em;
    font-size: small;
}

.slider-menu-container {
    display: flex;
    justify-content: end;
}

.cmph-header {
    font-weight: bold;
    font-size: medium;
}

.lbl-data-div {
    padding-left: 0px;
}
.margin-search-box{
    margin-left: 7%;
}