.customerLossPanel Label{
    font-weight: bold;
    margin-top: 12px;
    font-size: 14px !important;
}

.cst-dv-loss-main {
    display: flex;
    flex-direction: column;
}

.cst-dv-actions {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-top: 0.5em;
}

.expander-cell-td {
    padding: 0.75em 0.1em 0.75em 0.75em  !important;
}

