.documate-todoList-main-div {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    padding: 0.5em 0em 0.5em 0em;
}
.todoList-userDropdown .rbt-input-multi .rbt-input-main {
    font-size: 1rem;
}
#documateToDoListGrid {
    input.cstGridCheckBox {
        margin-bottom: 0px !important;
}
}

