.notebox{
    border: 1px #cccccc solid;
}
.note-main-box{
    height:765px;
    padding-top: 0px;
    overflow-y:scroll;
}
.customerNotePanel Label{
    font-weight: bold;
    margin-top: 12px;
    font-size: 14px !important;
  }
.cstNoteDate div:has(> label.cst-Feedback-Linebreak){
    margin-top: -25px;
}