.miniTabs .nav-link{
    transition: color 0.5s ease, background-color 0.5s ease;
}
.miniTabs .nav-link.active{
    color: #fff;
    background-color: #207ab7;
}

.tabContent {
    transition: opacity 0.5s ease;
}
.button-row {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.bottom-stick-actions {
    display: flex;
    margin: 0.5em 0em;
}
.employee-details{
    height: 500px;
}