.main-cst-lss-div {
    padding: .5em;
}


.cst-lss-section-header {
    font-weight: bold;
    font-size: 1.1em;
}

.col-section-header {
    padding-left: 0.5em;
    margin-bottom: 1em;
}

.col-individual-input {
    padding-left: 0em;
}

.col-individual-lable {
    padding-left: 0px;
}

.individual-lable {
    padding-left: 0em;
}

.cst-lss-descr-input {
    height: 17rem !important;
}

.shrink-astrik {
    padding-right: 1em;
}

