.activityTable {
    position: relative;
  }

  .activityTable tr.non-collapsible.alternate {
    background-color: #f2f2f2;
}

  .activityRow:hover {
    background-color: rgb(214, 214, 214) !important;
  }

  .addActivityPanel label{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;
    margin-top: 12px;
  }

  .truncateDesc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  .activity-action-select {
    margin-top: -0.8rem !important;
  }

  .tbl-header{
    text-align: start;
  }

  .wrapDesc {
    white-space: normal;
    word-wrap: break-word;
  }

  .descScroll {
    max-height: 100px;
    overflow-y: auto;
    max-width: 35rem;
  }

  .actionTypeCol {
    width: 10%;
  }

  .dateCol {
    width: 10%;
  }

  .timeCol {
    width: 10%;
  }

  .transTypeCol {
    width: 10%;
  }

  .descCol {
    width: 25%;
  }

  .policyCol {
    width: 10%;
  }

  .effDateCol {
    width: 10%;
  }

  .csrCol {
    width: 15%;
  }

  .tableHeader {
    background-color: white;
    color: black;
    border: none;
  }

  .activityTimePicker{
    width: 7.5em;
    height: 40px;
  }

  .activityDatePicker {
    width: 8.5em;
    height: 40px;
  }

  .description-lable-error {
    margin-top: 1em;
  }

  .actity-type-label-error {
    margin-top: 0em;
  }
  
  .labelClass {
    font-weight: bold;
  }