$theme-colors: (
  "primary": #207ab7,
  "secondary": #009b9e,
  "success": #198754,
  "warning": #ffc107,
  "danger": #dc3545,
  "info": #177db8,
  "light": #fdfffc,
  "dark": #011627,
  // "saphire": #002568 ,
  // "dark-blue": #004c97,
  "light-grey": lightgray,
  "tertiary": gray,
  "second":gray
);
