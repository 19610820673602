.insurance-product-list {
    display: flex;
    align-items: center;
  
    .product-section {
      display: flex;
      gap: 0.25rem;
      padding: 0.5rem;
      min-height: 3rem;
      align-items: center;
    }
  
    .separator {
      width: 1px;
      height: 2.5rem;
      border-left: 2px dotted #e2e8f0;
      margin: 0 0.5rem;
    }
  
    .insurance-circle {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      background: #f8fafc;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;
      border: 1px solid #e2e8f0;
  
      &:hover {
        transform: scale(1.1);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
    }
  
    .insurance-icon {
      font-size: 1.1rem;
      color: #F8F9FA;
  
      &.active {
        color: #10b981;  // Green
      }
  
      &.inactive {
        color: #94a3b8;  // Gray
      }
  
      &.pending {
        color: #f59e0b;  // Orange/Amber
        color: var(--bs-danger)
      }
    }
  }
  
  // Responsive adjustments
  @media (max-width: 768px) {
    .insurance-product-list {
      flex-wrap: wrap;
      justify-content: center;
      
      .separator {
        display: none;
      }
  
      .product-section {
        width: 100%;
        justify-content: center;
        border-bottom: 1px dotted #e2e8f0;
        padding: 1rem 0;
  
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }