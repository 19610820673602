  .outerPanel{
    /* padding-left: 27px;
    padding-top: 10px;
    padding-bottom: 10px; */
  }
  .padding-bottom10{
    padding-bottom:10px !important ;
  }
  /* .inlineLable{
    text-align: left;
      padding-left: 10px !important;
      color: black;
      font-size: 14px !important;
      font-family: "Roboto", sans-serif;
      font-weight: bold;
      font-style: normal;
      letter-spacing: 1.5px;
  }
  .cstPanel .row>[class^="col-lg"]{
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    height: 52px !important;
    text-align: -webkit-left;
    
  } */
  .cstPanel Label.normalText{
    font-weight: normal;
    margin-top: 12px;
    font-size: 14px;
  }

  .historyPanel-boldText{
    font-weight: bold;
    margin-top: 8px;
    font-size: 12px;
  }

  .historyPanel-normalText{
    font-weight:normal;
    margin-top: 8px;
    font-size: 12px;
  }

  /* .cstPanel Label, .errLabel{
    font-weight: bold;
    margin-top: 12px;
    font-size: 14px !important;
  } */

  
  /* .cstCampaignSetting .rbt-input{
    margin-bottom: 0px!important;
  } */
  /* .cstPanel input, .filter-row-height button{
    margin-top: 5px;
  } */

  /* .cstPanel textarea, .filter-row-height button{
    margin-top: 5px;
  }
  */
  .filter-row-height input, .filter-row-height button{
    margin-top: 0px;
  }
  /* .filter-row-height input{
    font-size: calc(10px + 0.390625vw);
  }
  .filter-row-height button{
    font-size: calc(2px + 0.390625vw);
  } */
  /* .cstDetailSection{
    border-left:1px solid rgba(0, 0, 0, 0.125); 
    border-right: 1px solid rgba(0, 0, 0, 0.125);
   border-top: 1px solid rgba(0, 0, 0, 0.125);
  } */
  /* one more hidden class available at 330*/
  .display-none{
    display: none !important;
  }
  .filter-row-height{
  height: 100% !important;
  }
  .filter-row-height.row, .filter-row-height.row>[class^="col-lg"],.filterRow>.col-lg-2, .filterRow>.col-lg-10 {
    border:none !important;
  }
  .filterRow{
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  .bottom-margin-filter{
    margin-bottom: 5px;
  }
 

  .top-margin-filter{
    margin-top: 5px;
  }

  #txtQueryString{
     resize: none;
     height:400px;
  }
  #txtDescription{
    resize: none!important;
    height:40px;
 }
 
  .querylabel{
    font-weight: normal !important;
  }

  .query-filter-input {
    width:100%;
    height:50px!important;
    font-size:small;
   }
   
   .nopadding{
     padding:0px!important;
   }

  .nopadding-right{
    padding-right:0px!important;
  }


  .cstDeleteIconBtn{
    background-color: transparent !important;
  }
  .cstDeleteIconBtn:hover{
    color: white !important;
    background-color:gray !important;
  }
  /* .success-message{
    background-color: #a3f7a3;
    font-weight:bold;
    color:white;
    height: 50px!important;
    margin-bottom: 5px !important;
    padding-top: 12px!important;
    border-radius: 25px;
  }
  .error-message{
    background-color: rgb(245, 174, 174);
    color:white;
    font-weight: normal;
    height: 50px!important;
    margin-bottom: 5px !important;
    padding-top: 12px!important;
    border-radius: 25px;
  }
  .success-message>span{
    text-align: right;
    color: black;
  } */

  .cst-error{
    margin-top: -1.25rem !important;
  }
  .cst-back{
    border: none !important;
    background-color: inherit !important;
  }
  .padding-left26{
    padding-left: 26px !important;
  }
  .padding-left10{
    padding-left: 10px !important;
  }
  .max-width-0{
    max-width: 0px !important;
  }
  .font-bold{
    font-weight: bold!important;
  }
  /* #QueryDetails input, #PanelDetails input {
    margin-bottom:0px !important ;
  }  */
  /* #QueryDetails .row>[class^="col-lg"] {
    height: 64px !important;
  } */
  .texbox-input {
    width: 100%;
    height: 40px !important;
    font-size: 14px;
}
.feedback-row{
    border-bottom:1px solid rgba(0, 0, 0, 0.125);
}
.feedback-row-filter{
  border-bottom:1px rgba(0, 0, 0, 0.125);
}
.cst-Feedback-Linebreak{
  /* content: "\a"; */
  white-space:break-all;
}
 .upmargin20{
   margin-top: -20px;
 }
 .upmargin24{
  margin-top: 24px;
}
 .rbt-token {
   color:black!important
 }
 .cstFilterInput{
   min-height: 40px !important;
 }
 .rbt-input-multi{
  height: 40px !important;
  z-index: 9;
  overflow-y: auto;
 }

 .filter-row-height .rbt-input-multi .rbt-input-main{
  margin: -6px 0 4px;
 }
 .cstNotePanel .row>[class^="col-lg"]{
  border-bottom:none!important;
 }

 .add-New-Note{
  margin-left: 0px !important;
  margin-right: 100px !important;
 }
 .cst-font-div, .cst-font-div>label{
   font-size: 13px !important;
 }

 .cst-font-row{
  font-size: 15px !important;
  
 }
 .cst-font-col{
  border-bottom: gray solid 1px;
  padding-top: 10px;
  padding-left: 25px;
 }
 #btnNotesSearch{
   margin-top: 5px!important;
   margin-left:25px;
 }

 input[type="search"]:focus {
  color: #495057;
  background-color: #fff;
  border-color: #70b7e6;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(32, 122, 183, 0.25);
 }
 .search-icon
  {
    margin-left: -21px;
    color: #aaaaaa;
}
.icon-color{
  color: #aaaaaa;
}
.disabled-icon{
  color:#aaaaaa7a;
  cursor: pointer;
}

.text-right{
  text-align: right;
}
.text-left{
  text-align: left;
}
#txtNotes{
  font-size:14px;
  height:100px;
  overflow-x:visible;
}
/* .cstNewNote:has(#txtNotes){
  padding-top: 10px;
} */
.margin-Left-15{
  margin-left: -15px;
}
.margin-Left22{
  margin-left: 22px;
}

.margin-Left-22{
  margin-left: -22px;
}
 
.visibility{
  visibility: hidden!important;
}
.content-visible{
  display: none !important;
}
.nomargin-bottom{
  margin-bottom: 0px !important;
}

.nomargin-top{
  margin-top: 0px !important;
}

#NewValue.auto-height{
  height:auto !important;
}
.margin-bottom-20{
  margin-bottom: -20px;
}
.padding-top-20{
  padding-top: 20px;
}
.search-input{
  margin-top: 5px;
}

.footer-Button {
  margin-left: 10px;
  width: 20%;
}
.cst-error-row-style{
  height: 90px!important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.margin-top30{
  margin-top: 30px !important;
}
.data-type-value{
  height: 60px;
  margin-left: -15px;
  width: 102%;
  resize: none;
}
.hidden{
  display: none;
}
.filter-row-height.row.filter-criteria-section{
  border-bottom: 1px solid rgba(0, 0, 0, 0.125)!important;
  margin-top: 5px;
}
.top-margin-filter {
  margin-top: 5px;
}
/* .filter-criteria-section:nth-child(odd){
  background-color:  rgba(0, 0, 0, 0.125);
} */

span.css-kud8pv{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /* width: 100%;
  height: 100%; */
  /* background: rgba(0, 0, 0, 0.4); */
  z-index: 1;
  margin: auto;
}
.block-ui {
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
  opacity: 0.4;
  pointer-events: none;
  position: absolute;
}
.disable-event{
  pointer-events: none;
}

.react-datepicker-popper {
  z-index: 9998 !important;
}

th:has(.ui-datepicker){
  overflow:visible
}
  /* table-container>tr>th:has(.ui-datepicker){
    overflow:visible
  } */
  input[name="Created on"],
    .ui-datepicker>.input-group{
    height:30px
  }
  .table-container:has(#tblExternalTemplate){
    max-height: initial;
  }
  .table-container:has(#tblQueryList){
    max-height: initial;
  }
  .table-container:has(#tblCampaignList){
    max-height: initial;
  }
  button#btnInactive{
    width:21%
  }
  .no-boder-bottom{
    border-bottom: none !important;
  }
  .ellipsis{
    text-overflow:ellipsis;
    overflow: hidden;
    /* white-space: nowrap; */
  }

  .css-fix {
    white-space: pre-wrap; /* or pre-line */
  }
  .filter-error-col-height{
    margin-bottom: 22px;
  }
   #AddNew.disabled:hover{
     cursor: pointer;
   }
   .notes-body-card{
    text-align: left;
    height:875px;
    padding-top: 0px;
    overflow-y:scroll;
   }
   .highlight-inactive{
    color: red;
   }
   .modal-content-class{
     max-width:50% !important
   }
   .Toastify__close-button {
     visibility: hidden !important;
   }
   .rbt-input-multi::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .rbt-input-multi {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .modal-body input, .modal-body input-group{
    font-size: 0.9rem !important;
    height:40px !important;
    border-radius: 0.25rem;
  }
 
  .form-control.highlight-inactive:focus,.errLabel{
    color: red;
  }
  .rbt-token.highlight-inactive{
    color: red !important;
  }

  .fc-day-number{
    color: white !important;
  }

  .stripe-background {
    background-color: rgb(115, 115, 115);
    background-image: repeating-linear-gradient(
      45deg,
      rgba(255,255,255,0.1),
      rgba(255,255,255,0.1) 10px,
      transparent 10px, 
      transparent 20px
    );
    background-size: 28px 28px;
    position: relative;
  }

  .fc-view {
    border-right: 1px solid #d5d5d5 !important;
    border-bottom: 5px solid #d5d5d5 !important;
  }

 /* Grid Templates --- Start */
 .grd-center-content {
  display: flex;
  justify-content: center;
}

.grd-center-content--edit-action {
  margin-bottom: 1.6em;
}

.grd-cancel-button {
  margin-left: 0.5em;
}

.grd-date-time-picker-popper {
  position: fixed !important;
}

.grd-datetime-picker-div {
  width: -webkit-fill-available;
  position: relative;
}

.grd-edit-cell-td {
  display: flex !important;
  justify-content: center;
  flex-direction: column;
}

.grd-edit-cell-select-icon {
  height: 65% !important;
}

.grd-cell-icon {
  font-size: 1.5em;
}

.grd-cell-icon--save {
  border: 1px solid transparent;
  color: #207ab7;
  padding: 0.3em;
}

.grd-cell-icon--save:hover {
  border: 1px solid;
  padding: 0.3em;
  border-radius: 0.2em;
}

.grd-cell-icon--cancel {
  border: 1px solid transparent;
  color: gray;
  padding: 0.3em;
}

.grd-cell-icon--cancel:hover {
  border: 1px solid;
  padding: 0.3em;
  border-radius: 0.2em;
}

.grd-cell-icon--edit {
  border: 1px solid transparent;
  color: #207ab7;
  padding: 0.3em;
}

.grd-cell-icon--disabled {
  border: 1px solid transparent;
  color: lightgrey;
  padding: 0.3em;
}

.grd-cell-icon--edit:hover {
  border: 1px solid;
  padding: 0.3em;
  border-radius: 0.2em;
}

.grd-cell-icon--delete {
  border: 1px solid transparent;
  color: #207ab7;
  padding: 0.3em;
}

.grd-cell-icon--delete:hover {
  border: 1px solid;
  padding: 0.3em;
  border-radius: 0.2em;
}

.grd-cell-icon--lock {
  border: 1px solid transparent;
  color: #207ab7;
  padding: 0.3em;
}

.grd-cell-icon--hardlock {
  border: 1px solid transparent;
  color: white;
  background-color: #207ab7;
  padding: 0.3em;
}

.grd-cell-icon--lock:hover {
  border: 1px solid;
  padding: 0.3em;
  border-radius: 0.2em;
}

.grd-cell-icon--working {
  border: 1px solid transparent;
  color: #207ab7;
  padding: 0.3em;
}

.todoList-grd-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3em;
}

.todoList-grd-cell--checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3em;
}

/* Grid Templates --- Ends */


/* .cstAnimation-center{
  transition: all .5s linear;
}

.cstAnimation-sidebar{
  transition: all .5s linear;
} */

/* .cstAnimation-transition{
  transition: all .5s linear;
} */

.modal-message-paragraph {
  padding-left: 0.2em;
}

.btn-wrap-text {
  overflow: hidden;
white-space: nowrap;
display: inline-block;
text-overflow: ellipsis;
}

.filter-radio {
  margin-left: 0.8em;
}
 /*.nav-bar-item-width:hover{
  text-shadow: 0 0 .65px #333, 0 0 .65px #333;
}

.nav-bar-item-ex-width{
  width: 23%;
} */

nav.navbar form.form-inline .form-check .form-check-input {
  margin-top: 0
}

  .calendarsearch-li {
    font-size: 14px;
    padding: 5px;  
  }
  
  .calendarsearch-li:hover {
    background-color: #e5e5e5;
  }
  .no-border-bottom {
    border-bottom: none !important;
  }
  
  .dynamic-query-filter{
    height: 27px !important;
  }

  .uniform-font-size{
    font-size: 15px;
  }
  .cst-dynamic-ddl .rbt-input-multi{
    height: 38px !important;
  }

  .nested-panel-header{
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0px;
  }
  .nested-panel-header .card-body{
    padding-left: 25px;
  }
  .cstPanel .row.panel-header-row > [class^="col-lg"]{
    border-bottom: none !important;
    height: auto !important;
    text-align: left !important;
  }
  .cstPanel>.section-height{
    height:auto;
  }

  .form-chkebox {
    font-weight: bold;
    margin-top: 0.2em;
    font-size: 14px !important;
  }

  .mat-text-input-icon {
    font-size: 1.5em;
  }

.bt-main-action {
    color: white;
    font-weight: 300;
}

.entry-form-inlineLable {
  vertical-align: sub;
  margin-top: 0.1em;
}

.new-entry-main-title {
  font-weight: bold;
  font-size: 1.2em;
}

.action-btn-base {
    min-width: 7.4rem;
}


.cs2-lables {
  font-weight: bold;
}
  .lable-error {
    font-size: 14px !important;
    font-weight: 700;
    margin-top: 0.5em;
    color: red;
}

.custom-dropdown {
  position: relative;
}

.custom-dropdown--error-select-div {
  margin-top: 1em;
}

.cust-filter-add-new-div {
  display: flex;
  justify-content: space-between;
}

.custom-dropdown .fa-angle-down {
  position: absolute;
  right: 16px;
  height: 100%;
}
.custom-dropdown .form-control {
  padding-right: 36px;
}
.custom-dropdown .form-control.is-invalid{
  background-image: none;
}
.custom-dropdown .rbt-input-multi
{
  z-index: 0;
}
.dropdown-autocomplete-arrow{
 text-align: center;
  width: 12px;
  line-height: 10;
  z-index: 1;
}

.icon-disabled{
  opacity: 0.5;
  pointer-events: none;
}
.note-span {
    /* float: left; */
    cursor: pointer;
    /* border-bottom: gray 0.1em solid;
    padding-right: 0px;
    margin-left: -4px; */
}

#customer-main .nav-link-custom.disabled {
    color: rgb(62, 61, 61) !important;
}
