.info-display {
  display: flex;
  align-items: center;
  gap: .5rem;
  
  &.bordered {
    padding: 0.25em 0.75em 0.25em 0.5em;
  border: 1px dashed rgba(0, 0, 0, .25);
  border-radius: 6px;
  }

  &:not(.bordered) {
    padding: 0.0em;
  }

  .info-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-color {
      color: #6B7280;
    }
  }

  .info-content {
    flex: 1;

    .info-caption {
      font-size: .8em;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 700;
    }

    .info-value-wrapper {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .info-value {
        // font-weight: 700;

        &.clickable {
          color: #2563EB;
          text-decoration: none;
          font-weight: 700;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .copy-button {
    background: none;
    border: none;
    padding: 0.25rem;
    color: #d9d9d9;
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: #374151;
    }
  }
}

.tooltip-animation {
  &.show {
    animation: slideUpFade 200ms ease-out;
  }
}

@keyframes slideUpFade {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}