.policy-summary-header {
    .header-row {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 0.25rem;
  
      .term-title {
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        font-size: 18px;
        margin: 0;
      }
  
      .status-badge {
        padding: 0.25rem 0.75rem;
        border-radius: 4px;
      }
    }
  
    .subtitle-row {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      color: #4B5563;
  
      .subtitle-item {
        display: flex;
        align-items: center;
        gap: 0.5rem;
  
        svg {
          color: #6B7280;
        }
      }
    }
  }