
.main-mat-text-input {
    display: flex;
    flex-direction: row;
    border:1px solid #ced4da;
    padding: 0.1em;
    border-radius: 0.5em;
    background-color: white;
}

.main-mat-text-input-error {
    border:1px solid red;
}

.main-mat-text-input-focus {
    border-color: #70b7e6;
    box-shadow: 0 0 0 .2rem #207ab740;
    color: #495057;
    outline: 0;
    border:1px solid transparent;
}

.mat-txt-input-lable {
    position: absolute;
    cursor: text;
    margin-left: 1em;
    margin-top: 1em;
}

.mat-txt-input-lable-focus {    
    margin-top: 0.1em;
    margin-left: 0.8em;
    font-size: 0.8em;
}

.mat-txt-input {
    margin: 0.5em 0em 0em 0em;
    border: none;
    line-height: 1em;
    padding-top: 1.1em;
    width: -webkit-fill-available;
}

.mat-txt-input:hover {
    outline: none;
}

.mat-txt-input:focus {
    outline: none;
}

.mat-input-icon-div {
    display: flex;
    align-self: center;
}

.mat-txt-input-error-div {
    display: flex;
    flex-direction: column;
}