// .roles-sidebar {
//     background-color: white;
//     overflow-y: auto;
//     height:100%;
// }

.roles-sidebar {
    // border-right: 1px solid #dee2e6;
    
    .role-item {
      cursor: pointer;
      
      &:hover {
        background-color: rgba(0,0,0,.075);
      }
      
      &.active {
        color: #207ab7;
        font-weight: 500;
      }
    }
  }