.close {
    color: #fff; 
    opacity: 0.7;
    font-weight: 100;
    font-size: 1.75rem;
}
.modal-title{
    opacity: 0.7;
    font-size: large;
}
.modalHeader{
    color:white;
    background-color: #104569;
    padding-top:0.5rem;
    padding-bottom:0.5rem;
}