
.slider-contact-main-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    font-weight: bold;
}

.sld-ctc-txt-input-row {
    margin-top: 0.5em;
}

.sld-ctc-action-row {
    margin-top: 1.5em;
}