.doc-viewer-container {
    display: flex;
    flex-direction: column; /* Stack the viewer-container */
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
}

.viewer-container {
    display: flex;
    flex-direction: column; /* Stack header, document, and toolbar vertically */
    flex-grow: 1;
    justify-content: space-between;
    overflow: auto;
}

.doc-viewer-header {
    display: flex;
    flex-direction: row; /* Ensure header elements are in a row */
    justify-content: space-between;
    align-items: center; /* Vertically center the header content */
    padding: 10px;
    background-color: #f9f9f9;
}

.doc-viewer {
    flex-grow: 1;
    display: flex;
    justify-content: center; /* Horizontally center the document */
    overflow: auto; /* Allow scrolling for zoomed content */
    background-color: #fff;
    margin: 0 10px;
    text-align: center;
}

.toolbar {
    display: flex;
    justify-content: center; /* Center the toolbar horizontally */
    align-items: center;
    padding: 5px;
    background-color: #f9f9f9;
}

.toolbar button {
    margin: 0 5px;
    padding: 8px 12px;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    border: none;
}

.toolbar button:hover {
    background-color: #207ab7;
    border-color: #207ab7 !important;
}

.toolbar button:disabled,
.toolbar button:disabled:hover {
    background-color: #f0f0f0;
    border-color: #ccc;
    color: #ccc;
    cursor: not-allowed;
}

.toolbar button {
    color: #207ab7;
    font-size: 18px;
    transition: color 0.3s ease;
}

.toolbar button:hover {
    color: white;
}

.toolbar button:disabled{
    color: #207ab7;
    background-color: white;
}

.timestamp-container {
    padding: 10px;
    background-color: #ffffff;
    border-top: 1px solid #ccc;
    display: flex; 
    justify-content: space-between;
    width: 100%;
  }

.close-viewer {
    cursor: pointer;
    font-size: 18px;
    color: #ff0000;
    margin-left: 10px;
}
.pageDisplay {
    font-size: 18px;
    color: black;
    padding: 8px 12px;
}
