
// .adminHeader {
//   cursor: pointer;
//   font-weight: bold;
//   font-size: medium;
//   color: rgba(0, 0, 0, 0.9);
//   line-height: inherit;
//   white-space: nowrap;
//   font-family: sans-serif;
//   padding-top: 0.3125rem;
//   padding-left: 2.5rem;
//   margin-top: 0.5rem !important;
// }

// .AdminDropdown {
//   margin: 0 1em;

//   .dropdown-menu {
//       color: white !important;
//       text-decoration: none;
//       background-color: #207ab7;
//     }

//     .active {
//       color: #fff !important;
//       font-weight: bold !important;
//       border-bottom: 0.15em solid darkorange !important;
//   }
    
//     .dropdown-item {
//       color: white;
//     }
    
//     .dropdown-item:hover {
//       background-color: white;
//       color: black;
//     }

//     .btn-success:not(:disabled):not(.disabled), .show > .btn-success.dropdown-toggle:focus{
//       box-shadow: none !important;
//       color: white;
//       background-color: #207ab7;
//       border-color: #207ab7;
//     }

//     .btn {
//       padding: 0 0.5rem !important;
//     }

// }
.nav-link {
  &.active {
    // color: #207ab7 !important;
    border-bottom: 2px solid #207ab7;
  }
}

.nav-item {
  .dropdown-toggle.active {
    color: #207ab7 !important;
    border-bottom: 2px solid #207ab7;
  }
}
.adminNavLink{
  color: white !important; /* Default color */
  text-decoration: none;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0.5rem ;
  padding-right: 0.5rem;
  margin: 0 1em;
.btn-success {
  color: white !important;
  background-color: #207ab7 !important;
  border-color: #207ab7 !important;
}
}

.adminNavLink.active {
color: #fff !important; 
font-weight: 700 !important;
border-bottom: 0.15em solid darkorange !important;
}