
.sld-note-section-header {
    font-weight: bold;
    font-size: 1.1em;
}

.sld-note-first-row {
    margin-top: 0.5em;
}

.sld-note-descr-input {
    height: 15rem !important;
    margin-bottom: 2.2em;
}

.sld-note-input-error {
    margin-top: 0em;
}

.sld-note-date {
    max-width: 9em;
    height: 2.6em;
}

.sld-note-date-col {
    display: flex;
    justify-content: end;
}

.sld-note-lable {
    padding-top: 0.4em;
    margin-right: 1em;
}

.sld-note-col {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.sld-note-action-row {
    margin-top: 1em;
}
