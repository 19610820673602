.rct-pgn-btn {
    margin-left: 0.2em;
    border-width: 0em;

    &--icon {
        font-size: 0.8em;
    }

    &--active {
        background-color: #edf1f7;
        font-weight: bold;
    }
}