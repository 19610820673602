

// TODO : Will remove this later on
.temp-component-place-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    font-weight: bold;
}


.customer-slider {
}

// .cst-main-placeholder-first-column {
//     padding-right: 1em;
// }

// .cst-main-placeholder-second-column {
//     padding: 0em;
// }